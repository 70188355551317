import axios from "@/plugins/axios";

export default {
  namespaced: true,

  state: {
    activities: [],
  },
  mutations: {
    SET_ACTIVITIES(state, value) {
      state.activities = value
    },
  },
  getters: {
    getActivities(state) {
      return state.activities
    },
  },
  actions: {
    async getAllActivities({ commit }) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/activities').then(res => {
          commit('SET_ACTIVITIES', res.data)
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
