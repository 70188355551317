export default [
    {
        icon: 'pi pi-eye', name: 'Supervision', label: 'SUPERVISION', children:
        [
            {icon: 'pi pi-map-marker', name: 'Map', label: 'Carte', to: 'Map'},
            {icon: 'pi pi-th-large', name: 'SupervisionSites', label: 'Sites', show: true, children: []},
        ]
    },
    {
        icon: 'pi pi-wrench', name: 'Management', hidden: true, roles: ['ADMIN'], label: 'GESTION', children:
        [
            {icon: 'pi pi-building', name: 'ManagementSites', label: 'Sites', to: 'ManagementSites'},
            {icon: 'pi pi-th-large', name: 'ManagementEquipments', label: 'Équipements', to: 'ManagementEquipments'},
            {icon: 'pi pi-user', name: 'ManagementUsers', label: 'Utilisateurs', to: 'ManagementUsers'},
            {icon: 'pi pi-history', name: 'ManagementActivity', label: 'Activité', to: 'ManagementActivity'},
        ]
    },
]