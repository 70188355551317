import axios from "@/plugins/axios";

export default {
  namespaced: true,

  state: {
    defaultState: null,

    loadingSites: false,

    loading: false,
    globalLoading: false,

    noPaddingLayout: false,

    initializedSelectedData: false,

    selectedSitesDtColumns: [],
    selectedGatewaysDtColumns: [],
    selectedLoraGatewaysDtColumns: [],
    selectedDevicesDtColumns: [],
    selectedLoraDevicesDtColumns: [],
    selectedDataSourcesDtColumns: [],
    selectedLoraDataSourcesDtColumns: [],
    selectedAlertsDtColumns: [],
    selectedZonesDtColumns: [],
    selectedZonesMgmtDtColumns: [],
    selectedEquipmentsDtColumns: [],
    selectedDatapointsDtColumns: [],
    selectedEquipmentsMgmtDtColumns: [],
    selectedDatapointsMgmtDtColumns: [],
    selectedSchedulersDtColumns: [],
    selectedUserGroupsDtColumns: [],
    selectedUsersDtColumns: [],
    selectedProfilesDtColumns: [],



    lastSelectedCustomers: [],
    lastSelectedCustomer: null,
    lastSelectedSites: [],
    lastSelectedSite: null,
    lastSelectedPeriod: null,
    lastSelectedRefreshOption: null,

    toastMessage: null
  },
  mutations: {
    SET_LOADING(state, val) {
      state.loading = val
    },
    SET_SELECTED_SITES_COLUMNS (state, val) {
      state.selectedSitesDtColumns = val
    },
    SET_SELECTED_GATEWAYS_COLUMNS (state, val) {
      state.selectedGatewaysDtColumns = val
    },
    SET_SELECTED_LORA_GATEWAYS_COLUMNS (state, val) {
      state.selectedLoraGatewaysDtColumns = val
    },
    SET_SELECTED_DEVICES_COLUMNS (state, val) {
      state.selectedDevicesDtColumns = val
    },
    SET_SELECTED_LORA_DEVICES_COLUMNS (state, val) {
      state.selectedLoraDevicesDtColumns = val
    },
    SET_SELECTED_DATA_SOURCES_COLUMNS (state, val) {
      state.selectedDataSourcesDtColumns = val
    },
    SET_SELECTED_LORA_DATA_SOURCES_COLUMNS (state, val) {
      state.selectedLoraDataSourcesDtColumns = val
    },
    SET_SELECTED_ALERTS_COLUMNS (state, val) {
      state.selectedAlertsDtColumns = val
    },
    SET_SELECTED_ZONES_COLUMNS (state, val) {
      state.selectedZonesDtColumns = val
    },
    SET_SELECTED_ZONES_MGMT_COLUMNS (state, val) {
      state.selectedZonesMgmtDtColumns = val
    },
    SET_SELECTED_EQUIPMENTS_COLUMNS (state, val) {
      state.selectedEquipmentsDtColumns = val
    },
    SET_SELECTED_DATAPOINTS_COLUMNS (state, val) {
      state.selectedDatapointsDtColumns = val
    },
    SET_SELECTED_EQUIPMENTS_MGMT_COLUMNS (state, val) {
      state.selectedEquipmentsMgmtDtColumns = val
    },
    SET_SELECTED_DATAPOINTS_MGMT_COLUMNS (state, val) {
      state.selectedDatapointsMgmtDtColumns = val
    },
    SET_SELECTED_SCHEDULERS_COLUMNS (state, val) {
      state.selectedSchedulersDtColumns = val
    },
    SET_SELECTED_USER_GROUPS_COLUMNS (state, val) {
      state.selectedUserGroupsDtColumns = val
    },
    SET_SELECTED_USERS_COLUMNS (state, val) {
      state.selectedUsersDtColumns = val
    },
    SET_SELECTED_PROFILES_COLUMNS (state, val) {
      state.selectedProfilesDtColumns = val
    },


    SET_LAST_SELECTED_CUSTOMERS (state, val) {
      state.lastSelectedCustomers = val
    },
    SET_LAST_SELECTED_SITES (state, val) {
      state.lastSelectedSites = val
    },
    SET_LAST_SELECTED_CUSTOMER (state, val) {
      state.lastSelectedCustomer = val
    },
    SET_LAST_SELECTED_SITE (state, val) {
      state.lastSelectedSite = val
    },
    SET_LAST_SELECTED_PERIOD (state, val) {
      state.lastSelectedPeriod = val
    },
    SET_LAST_SELECTED_REFRESH_OPTION (state, val) {
      state.lastSelectedRefreshOption = val
    },

    SET_TOAST_MESSAGE (state, val) {
      state.toastMessage = val
    }
  },
  getters: {
    getLoading (state) {
      return state.loading
    },
    getSelectedSitesDtColumns (state) {
      return state.selectedSitesDtColumns
    },
    getSelectedGatewaysDtColumns (state) {
      return state.selectedGatewaysDtColumns
    },
    getSelectedLoraGatewaysDtColumns (state) {
      return state.selectedLoraGatewaysDtColumns
    },
    getSelectedDevicesDtColumns (state) {
      return state.selectedDevicesDtColumns
    },
    getSelectedLoraDevicesDtColumns (state) {
      return state.selectedLoraDevicesDtColumns
    },
    getSelectedDataSourcesDtColumns (state) {
      return state.selectedDataSourcesDtColumns
    },
    getSelectedLoraDataSourcesDtColumns (state) {
      return state.selectedLoraDataSourcesDtColumns
    },
    getSelectedAlertsDtColumns (state) {
      return state.selectedAlertsDtColumns
    },
    getSelectedZonesDtColumns (state) {
      return state.selectedZonesDtColumns
    },
    getSelectedZonesMgmtDtColumns (state) {
      return state.selectedZonesMgmtDtColumns
    },
    getSelectedUserGroupsDtColumns (state) {
      return state.selectedUserGroupsDtColumns
    },
    getSelectedUsersDtColumns (state) {
      return state.selectedUsersDtColumns
    },
    getSelectedProfilesDtColumns (state) {
      return state.selectedProfilesDtColumns
    },
    getSelectedEquipmentsDtColumns (state) {
      return state.selectedEquipmentsDtColumns
    },
    getSelectedEquipmentsMgmtDtColumns (state) {
      return state.selectedEquipmentsMgmtDtColumns
    },
    getSelectedDatapointsDtColumns (state) {
      return state.selectedDatapointsDtColumns
    },
    getSelectedDatapointsMgmtDtColumns (state) {
      return state.selectedDatapointsMgmtDtColumns
    },
    getSelectedSchedulersDtColumns (state) {
      return state.selectedSchedulersDtColumns
    },
    getInitializedSelectedData (state) {
      return state.initializedSelectedData
    },





    getLastSelectedCustomers (state) {
      return state.lastSelectedCustomers.sort((a, b) => a.name.localeCompare(b.name))
    },
    getLastSelectedSites (state) {
      return state.lastSelectedSites
    },
    getLastSelectedCustomer (state) {
      return state.lastSelectedCustomer
    },
    getLastSelectedSite (state) {
      return state.lastSelectedSite
    },
    getLastSelectedPeriod (state) {
      return state.lastSelectedPeriod
    },
    getLastSelectedRefreshOption (state) {
      return state.lastSelectedRefreshOption
    },

    getToastMessage (state) {
      return state.toastMessage
    }
  },
  actions: {
    async fetchSelectedSites({ commit, state }) {
      const sites = []
      for (const site of state.lastSelectedSites) {
        const res = await axios.axiosApiInstance.get('/getSites/' + site.site_uuid)
        const siteUpdated = res.data.data[0]
        siteUpdated.zones_id.forEach(zone => zone.timeout /= 60)
        sites.push(siteUpdated)
      }
      commit('SET_LAST_SELECTED_SITES', sites)
    },
    // token and simulation are optional
  }
}
