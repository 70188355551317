<template>
	<div class="layout-topbar">
		<div class="layout-topbar-left">
<!--      <Button @click="$emit('toggleMenu')" class="justify-content-center" style="background: none; border: none; width: 50px; height: 50px; border-radius: 30px; margin-left: 20px;">-->
<!--        <i class="pi pi-align-justify" style="font-size: 2rem;"></i>-->
<!--      </Button>-->
			<router-link to="/" class="app-name mr-4 ml-4">
        <div class="text-center">
          <span style="font-size: 1.75rem">SYSTÈME DE PILOTAGE PARKPILOT</span>
        </div>
			</router-link>
		</div>

		<div class="layout-topbar-right">
<!--      <i :key="keyBell" class="pi pi-bell mr-6 ml-2" style="font-size: 2rem; color: white" v-badge.danger="unacknowledgedAlerts"></i>-->
<!--      <ul class="layout-topbar-actions">-->
<!--				<li :class="['profile-item', {'active-topmenuitem fadeInDown': activeTopbarItem === 'profile'}]">-->
<!--					<div class="profile-container" @click="onTopbarItemClick($event, 'profile')">-->
<!--            <a href="#">{{ me?.first_name.charAt(0) + me?.last_name.charAt(0) }}</a>-->
<!--            <div class="name-container">-->
<!--              <span class="name">{{ me?.first_name + ' ' + me?.last_name }}</span>-->
<!--              <span class="job">{{ me?.role.name }}</span>-->
<!--            </div>-->
<!--						<i class="pi pi-angle-down"></i>-->
<!--					</div>-->

<!--					<ul class="profile-menu fadeInDown">-->
<!--						<li>-->
<!--							<a href="" @click="logout()">-->
<!--								<span>Déconnexion</span>-->
<!--							</a>-->
<!--						</li>-->
<!--					</ul>-->
<!--				</li>-->
<!--			</ul>-->
		</div>
	</div>
  <Dialog v-model:visible="globalLoading" :show-header="false" modal class="loading-dialog">
    <ProgressSpinner />
  </Dialog>
</template>

<script>
import {mapGetters, mapState} from "vuex";
export default {
  emits: ['menubutton-click', 'topbar-menu-click', 'topbar-item-click', 'topbar-submenu-click', 'search-item-click', 'toggleMenu'],
  props: ['activeTopbarItem', 'topbarMenuActive', 'layoutColor'],
	data() {
		return {
      keyBell: 0,
      finishedSetup: false,
      unacknowledgedAlerts: 0,
      selectedSites: [],
      selectedCustomers: [],
      customers: null,
      logo: require('@/assets/logo-parkelec.png'),
			menu: [
				{
					label: 'Menu',
					items: [
						{
							label: 'UI Kit', icon: 'pi pi-align-left',
							items: [
								{label: 'Form Layout', icon: 'pi pi-id-card', to: '/formlayout'},
								{label: 'Input', icon: 'pi pi-check-square', to: '/input'},
							]
						},
						{
							label: 'Hierarchy', icon: 'pi pi-align-left',
							items: [
								{
									label: 'Submenu 1', icon: 'pi pi-align-left',
									items: [
										{label: 'Submenu 1.1', icon: 'pi pi-align-left'},
										{label: 'Submenu 1.2', icon: 'pi pi-align-left'},
									]
								},
								{
									label: 'Submenu 2', icon: 'pi pi-align-left',
									items: [
										{label: 'Submenu 2.1', icon: 'pi pi-align-left'},
									]
								},
							]
						}
					]
				}
			]
		}
	},
  computed: {
    ...mapGetters({
    }),
    ...mapState({
      me: state => state.auth.me,
      globalLoading: state => state.misc.globalLoading
    }),
  },
  async created () {
    this.$store.state.misc.globalLoading = true
    this.$store.state.misc.globalLoading = false
  },
	methods: {
    async logout () {
      this.$store.state.misc.globalLoading = true
      await this.$store.dispatch('auth/logout')
      this.$router.push({ name: 'Login'})
      this.$store.state.misc.globalLoading = false
    },
    onMenuButtonClick(event) {
      this.$emit('menubutton-click', event);
    },
    onTopbarItemClick(event, item) {
      if(item === 'search' && this.$refs.input1) {
        setTimeout(() => {
          this.$refs.input1.$el.focus();
          this.$refs.input2.$el.focus();
        }, 400);
      }

      this.$emit('topbar-item-click', {originalEvent: event, item: item});
      event.preventDefault();
    },
	}
}
</script>


<style lang="scss" scoped>
::v-deep(.p-dropdown-label) {
  color: #1B2842
}
</style>