import axios from "@/plugins/axios";

export default {
  namespaced: true,

  state: {
    equipments: [],
  },
  mutations: {
    SET_EQUIPMENTS(state, value) {
      state.equipments = value
    },
  },
  getters: {
    getEquipments(state) {
      return state.equipments
    },
  },
  actions: {
    async getAllEquipments({ commit }) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/equipments').then(res => {
          commit('SET_EQUIPMENTS', res.data)
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateEquipment(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.put('/equipments', data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async createEquipment(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/equipments', data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateHighCurrents(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/equipments/mqtt/high_currents', data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async deleteEquipment(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.delete('/equipments/' + data._id).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
