import axios from "@/plugins/axios";

export default {
  namespaced: true,

  state: {
    events: [],
  },
  mutations: {
    SET_EVENTS(state, value) {
      state.events = value
    },
  },
  getters: {
    getEvents(state) {
      return state.events
    },
  },
  actions: {
    async getEventsByEquipment({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/events/' + data).then(res => {
          commit('SET_EVENTS', res.data)
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async downloadCsvDataForEquipment(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/events/csv/' + data.id, data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async deleteEquipmentDataWithRange(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/events/delete/' + data.id, data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getLastEventsByEquipment({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/events/last/' + data).then(res => {
          commit('SET_EVENTS', res.data)
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
