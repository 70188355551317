import axios from "@/plugins/axios";

export default {
  namespaced: true,
  state: {
    users: [],
  },
  mutations: {
    SET_USERS(state, value) {
      state.users = value
    },
  },
  getters: {
    getUsers(state) {
      return state.users
    },
  },
  actions: {
    async getAllUsers({ commit }) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/users').then(res => {
          commit('SET_USERS', res.data)
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateUser(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.put('/users', data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async createUser(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/users', data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async deleteUser(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.delete('/users/' + data._id).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async inviteUser(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/inviteUser', data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
