<template>
  <div class="min-h-screen flex relative lg:static" v-if="isLogged" @click="onDocumentClick">
    <Toast position="top-right" />
    <div class="layout-wrapper layout-static" :class="sideContent.isVisible ? 'wide' : ''">
      <AppTopbar @toggleMenu="toggleSidebarMenu" :activeTopbarItem="activeTopbarItem" :topbarMenuActive="topbarMenuActive" @menubutton-click="onMenuButtonClick" @topbar-menu-click="onTopbarMenuClick" @topbar-submenu-click="onTopbarSubmenuClick"
                 @topbar-item-click="onTopbarItemClick" @topbar-menuitem-click="onTopbarMenuItemClick" @search-item-click="onSearchItemClick"></AppTopbar>
<!--      <router-view name="header" />-->
      <Sidebar :current-sidebar-width="currentSidebarWidth"></Sidebar>
      <div :style="{width: (((1 - (currentSidebarWidth / windowWidth)) * 100) + '%'), 'margin-left': currentSidebarWidth + 'px' }" class="layout-content-wrapper" id="layout-content-wrapper"> <div id="layout-content" class="layout-content" :style="{padding: noPaddingLayout ? '0' : ''}"><router-view /></div></div>
    </div>
  </div>
  <div v-else>
    <Toast position="top-center" />
    <router-view/>
  </div>
  <Dialog v-model:visible="loading" :show-header="false" modal class="loading-dialog">
    <ProgressSpinner />
  </Dialog>
  <ConfirmDialog></ConfirmDialog>
</template>

<script>
import {mapGetters, mapState} from 'vuex'
import AppTopbar from "@/layouts/App/AppTopbar";
import Sidebar from "@/layouts/App/Sidebar";
import axios from "@/plugins/axios";

export default {
  name: 'App',
  components: {Sidebar, AppTopbar},
  computed: {
    ...mapGetters({
      globalToast: 'misc/getToastMessage'
    }),
    ...mapState({
      isLogged: state => state.auth.isLoggedIn,
      sideContent: state => state.sidebar.sideContent,
      toastAccessRight: state => state.auth.toastAccessRight,
      loading: state => state.misc.loading,
      noPaddingLayout: state => state.misc.noPaddingLayout
    }),
  },
  data () {
    return {
      menuMode: 'slim',
      theme: 'indigo',
      layoutColor: 'white',
      tempLayoutColor: 'white',
      lightMenu: true,
      staticMenuMobileActive: false,
      staticMenuDesktopInactive: false,
      menuClick: false,
      topbarMenuClick: false,
      topbarMenuActive: false,
      activeTopbarItem: null,
      menuActive: false,
      searchClick: false,
      windowWidth: document.documentElement.clientWidth,
      defaultSidebarWidth: 280,
      shrinkSidebarWidth: 0,
      currentSidebarWidth: 280
    }
  },
  watch: {
    globalToast (errors) {
      try {
        if (Array.isArray(errors)) {
          errors.forEach(error => {
            this.$toast.add({severity: 'error', summary: 'Erreur', detail: error.message, life: 5000})
          })
          this.$store.commit('misc/SET_TOAST_MESSAGE', null)
        }
      } catch (e) {
        console.log(e)
      }

    }
  },
  async created () {
    await this.$store.restored
    if (this.isLogged) {
      axios.axiosApiInstance.defaults.headers.common['Authorization'] = `Bearer ${this.$store.state.auth.token}`
    }
  },
  mounted () {
    window.addEventListener('resize', () => {
      this.windowWidth = window.innerWidth
    })
  },
  methods: {
    toggleSidebarMenu () {
      this.currentSidebarWidth = this.currentSidebarWidth === this.defaultSidebarWidth ? this.shrinkSidebarWidth : this.defaultSidebarWidth
    },
    onDocumentClick() {
      if (!this.topbarItemClick && !this.searchClick) {
        this.activeTopbarItem = null;
      }

      if (!this.topbarMenuClick) {
        this.topbarMenuActive = false;
      }

      this.searchClick = false;
      this.topbarItemClick = false;
      this.topbarMenuClick = false;
      this.menuClick = false;
    },
    onMenuButtonClick(event) {
      this.menuClick = true;
      this.topbarMenuActive = false;

      this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;

      event.preventDefault();
    },
    onTopbarItemClick(event) {
      this.topbarItemClick = true;


      if (this.activeTopbarItem === event.item)
        this.activeTopbarItem = null;
      else
        this.activeTopbarItem = event.item;

      event.originalEvent.preventDefault();
    },
    onSearchItemClick() {
      this.searchClick = true;
    },
    onTopbarMenuClick() {
      this.topbarMenuClick = true;
      this.topbarMenuActive = true;
    },
    onTopbarSubmenuClick(event) {
      this.topbarMenuClick = true;

      if (!event.item.items) {
        this.topbarMenuActive = false;
      }
    },
    onTopbarMenuItemClick(event) {
      this.topbarItemClick = true;

      if (this.activeTopbarItem === event.item)
        this.activeTopbarItem = null;
      else
        this.activeTopbarItem = event.item;

      event.originalEvent.preventDefault();
    },
    onMenuClick() {
      this.menuClick = true;
    },
  }
}
</script>

<style>

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-align: center;*/
  color: #2c3e50;
  /*height: 100vh;*/
  /*margin-top: 60px;*/
}
.router-view {
  height: calc(100vh);
}
/*Pour la largeur des pop-up de filtre*/
.p-column-filter-overlay, .p-column-filter-overlay-menu {
  width: auto !important;
}

</style>
<style lang="scss" scoped>
#layout-content {
  background-color: var(--surface-ground);
  padding: 1rem;
}

.wide .layout-content-wrapper {
  margin-left: 20rem !important;
  width: calc(100% - 20rem) !important;
}
</style>
