import {createStore} from 'vuex-extensions'
import VuexPersistence from "vuex-persist";
import { Store } from "vuex"

// MODULES
import sidebar from './modules/sidebar'
import misc from './modules/misc'
import auth from './modules/auth'
import sites from './modules/sites'
import equipments from './modules/equipments'
import users from './modules/users'
import activities from './modules/activities'
import events from './modules/events'
import alerts from './modules/alerts'

const vuexLocal = new VuexPersistence()

export const store = createStore(Store,{
  plugins: [vuexLocal.plugin],
  modules: {
    sidebar,
    misc,
    auth,
    sites,
    equipments,
    users,
    activities,
    events,
    alerts,
  },
})


