import axios from "@/plugins/axios";
import {store} from "@/store";

export default {
  namespaced: true,

  state: {
    sites: [],
  },
  mutations: {
    SET_SITES(state, value) {
      state.sites = value
    },
  },
  getters: {
    getSites(state) {
      return state.sites
    },
  },
  actions: {
    // eslint-disable-next-line
    async getAllSites({ commit }) {
      if (!axios.axiosApiInstance.defaults.headers.Authorization) {
        axios.axiosApiInstance.defaults.headers.common.Authorization = `Bearer ${store.state.auth.token}`
      }
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.get('/sites').then(res => {
          commit('SET_SITES', res.data)
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async createSite(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/sites', data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async updateSite(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.put('/sites', data).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async deleteSite(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.delete('/sites/' + data._id).then(res => {
          resolve(res.data)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
