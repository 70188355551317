import { createWebHistory , createRouter } from 'vue-router'
import appRoutes from './routes/app'
import { store } from '@/store'
const routes = [...appRoutes]

const router = createRouter({
    history: createWebHistory (),
    routes
})

router.beforeEach(async (to, from, next) => {

    if (to.name !== 'Login' && to.name !== 'Reset' && to.name !== 'AcceptInvite') {
        if (store.state.auth.isLoggedIn) {
            if (checkAccessRights(to, store.state.auth.me)) {
                store.state.misc.noPaddingLayout = to.name === 'Map';
                next()
            } else {
                store.state.misc.noPaddingLayout = from.name === 'Map';
                store.state.auth.toastAccessRight = true
                next({ name: from.name })
            }
        } else {
            store.state.misc.noPaddingLayout = false
            next({name: 'Login'})
        }
    } else {
        if (store.state.auth.isLoggedIn) {
            store.state.misc.noPaddingLayout = true
            next({ name: 'map_view' })
        } else {
            store.state.misc.noPaddingLayout = false
            store.dispatch('auth/logout')
            next()
        }
    }
})

const checkAccessRights = function (to, me) {
    if (to.meta?.roles) {
        return to.meta.roles.includes(me.role)
    } else {
        return true
    }
}

router.afterEach(() => {
    window.scrollTo(0, 0)
})

export default router
