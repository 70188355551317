// EN
// example simple { mykey: 'value' } | $t('mykey')
// example avec data { mykey: 'Bonjour {name}' } | $t('mykey', { name: 'toto' })
// example avec array { mykey: 'Bonjour {0} et {1} } | $t('mykey', ['toto', 'tata'])
const translation = {
    hello: 'Hello {name}',
    // SIDEBAR
    sidebar: {
        title: {
            commercial: 'Commercial',
            dashboard: 'Dashboard',
            paie: 'Paie / Cra',
            portes: 'Portés',
            mission: 'Mission',
            rh: 'RH',
            facturation: 'Billing',
            ndf: 'NDF',
            parrainage: 'Sponsorship',
            adminParams: 'Paramètres admins'
        },
        subItems: {
            label: {
                services: 'Services',
                portes: 'Portés',
                simulation: 'Simulation',
                createMission: 'Mission creation',
                prospect: 'Prospect',
                declarePaie: 'Déclaration de paie',
                declareFacture: 'Déclaration de facturation',
                historiquePaie: 'Historique de paie',
                preparationPaie: 'Préparation paie',
                importPaie: 'Import paie',
                historyPaie: 'Pays Historic',
                company: 'Company',
                commercial: 'Commercial',
                rh: 'HR',
                adv: 'Sales administration',
                pay: 'Wage',
                ndf: 'Expenses claim',
            }
        }
    },
    // DATATABLE
    datatable: {
        currentPageReportTemplate: '{first} à {last} sur {totalRecords} {target}',
        load: 'Loading {target}. Please wait',
        notFound: 'No {target} found',
        target: {
            missions: 'missions',
            portes: 'independents',
            salaires: 'salaries',
            data: 'data',
            services: 'services',
            factures: 'invoices',
            ndfs: 'notes de frais'
        }
    },
    admin: {
        params: {
            company: {
                // titles
                generalInformationTitle: 'General informations',

                socialRaisonLabel: 'Raison sociale',
                commercialNameLabel: 'Nom commercial',
                commercialStatusLabel: 'Statut',
                commercialCapitalLabel: 'Capital',
                commercialAddressLabel: 'Address',
                commercialZipCodeLabel: 'Zip code',
                commercialCityLabel: 'City',
                cityRegistrationRCSLabel: 'City registration RCS',
                commercialRcsLabel: 'Rcs',
                commercialSiretLabel: 'Siret',
                representativeLabel: 'Représentative',
                professionLabel: 'Profession',
                commercialConventionLabel: 'Convention',
                commercialLogoLabel: 'Logo',
                commercialLogoImportLabel: 'Import logo',
                registerButton: 'Enregistrer'
            },
            commercial: {
                // titles
                simulationTitle: 'Simulation :',
                commercialTitle: 'Commercial contract :',
                ikScaleTitle: 'IK Scale :',
                ikMajorationElectrique: 'Increase for electric vehicles :',
                // labels
                sourceLabel: 'How does he know us?' ,
                sourceSubLabel: 'Add options',
                managementRateLabel: 'Management rate',
                managementRateRoofLabel: 'Roof',
                managementRateRoofValueLabel: 'Value',
                transportLabel: 'Transport',
                transportCarLabel: 'Car',
                transportPublicLabel: 'Public transport',
                transportMotorBikeLabel: 'Motorbike',
                transportMotorcycleLabel: 'Motorcycle',
                financialReserveRateLabel: 'Financial reserve rate',
                financialReserveRateJuniorLabel: 'Junior',
                financialReserveRateSeniorLabel: 'Senior',
                financialReserveRateOthersLabel: 'Others',
                contractTypeLabel: 'Contract',
                contractTypeCddLabel: 'CDD',
                contractTypeCdiLabel: 'CDI',

                termsAndConditionsClientParticipationLabel: 'Terms and conditions of client participation',
                paymentMethodLabel: 'Payment method',
                exceptionalExpensesLabel: 'Exceptional expenses',
                rcpInsuranceLabel: 'Rcp Insurance',
                financialGuaranteeLabel: 'Financial guarantee',
                commercialCourtLabel: 'Commercial court',

                prospectSimulationTemplateLabel: 'Prospect simulation',
                particularConditionsContractTemplate: 'Kaizen special conditions contract template',
                generalConditionsContractTemplate: 'Model general conditions of the Kaizen commercial contract',
                personalGeneralConditionsContractTemplate: 'Import your own terms and conditions',

                ikCarLabel: 'Car',
                ikMotorbikeLabel: 'Motorbike',
                ikAutomatedScaleLabel: 'Automatic scale',
            },
            rh: {
                // titles
                creditCardTitle: 'Credit card of independent :',
                restaurantTicketsTitle: 'Restaurant tickets :',
                contractTitle: 'RH contract :',
                salaryGridTitle: 'Syntec Salary grid',
                // labels
                creditCardOption: 'Credit Card',
                creditCardOptionFees: 'Card fees',
                restaurantsTicketsOption: 'Restaurants tickets',
                restaurantsTicketsAmount: 'Amount:',
                breakLetterOption: 'Renunciation letter',

                particularConditionsWorkContractTemplate: 'Kaizen special conditions work contract template',
                generalConditionsWorkContractTemplate: 'Model general conditions of the Kaizen work contract',
                personalGeneralConditionsWorkContractTemplate: 'Import your own terms and conditions',

                dialogParticularConditionsWorkContractTemplate: 'Kaizen special conditions work contract',
                dialogGeneralConditionsWorkContractTemplate: 'General conditions of the Kaizen work contract',
                dialogPersonalGeneralConditionsWorkContractTemplate: 'Your own generals terms and conditions',
                dialogAgreementConventionWorkContractTemplate: 'Your own agreement convention',

                salaryGridPosition: 'Position',
                salaryGridCoefficients: 'Coefficients',
                salaryGridMinimumBrutSalary: 'Gross minimum wage',
            },
            adv: {
                // titles
                bankAccountTitle: 'Bank account :',
                factoringAccountTitle: 'Factoring account :',
                notificationsTitle: 'Independent notifications :',
                currencyTitle: 'Currency',
                invoiceEmailTitle: 'Invoice email',
                invoiceNaming: 'Invoice naming',
                // labels
                bankAccountLabel: 'Label',
                bankAccountIban: 'IBAN',
                bankAccountRib: 'RIB',
                bankAccountBIC: 'BIC',
                addBankAccountLabel: 'Add a new bank account',

                factoringAccountLabel: 'Label',
                factoringAccountBIC: 'BIC',
                addFactoringAccountLabel: 'Add a new factoring account',

                openInvoicingNotificationDate: 'Notification date of the invoicing opening',
                openInvoicingNotificationDateByEmail: 'By email',
                openInvoicingNotificationDateByEditor: 'On Kaizen',

                foreignCurrency: 'Foreign currency',
                invoiceReceptionEmail: 'Reception email for the porting company',
            },
            payroll: {
                // titles
                restaurantTicketsTitle: 'Restaurants tickets :',
                notificationsTitle: 'Notifications :',
                socialBenefitsTitle: 'Social benefits :',
                salarySimulationBulletinTitle: 'Simulation Salary Bulletin :',

                restaurantsTicketsOption: 'Restaurants tickets',
                restaurantsTicketsAmount: 'Amount:',

                openPayrollNotificationDate: 'Notification date of the payroll opening',
                openPayrollNotificationDateByEmail: 'By email',
                openPayrollNotificationDateByEditor: 'On Kaizen',

                peeLabel: 'PEE',
                percoLabel: 'PERCO',
                partialUnemploymentLabel: 'Partial unemployment',
                remoteWorkLabel: 'Remote work',
                remoteWorkFixedPriceLabel: 'Fixed price',
                majorMovementsOptionLabel: 'Major movements',
                majorMovementsDurationLabel: 'Duration',
                lunchFixedPriceLabel: 'Lunch fixed price',
                dinnerFixedPriceLabel: 'Dinner fixed price',
                hotelFixedPriceLabel: 'Hotel fixed price'
            }
        }
    },
    // SEND DOCUMENT DIALOG
    documentEmailTitle: 'Title',
    documentEmailSubject: 'Subject',
    documentEmailMessage: 'Message',
    documentEmailTitleRequired: 'Title is required',
    documentEmailSubjectRequired: 'Subject is required',
    documentEmailCancel: 'Close',
    documentEmailSendDocument: 'Send document',
    documentEmailDefaultName: 'Document',
    // CONTRACT TYPE DIALOG
    generatedSignedKey: 'Génération signé',
    generatedSimpleKey: 'Génération sans signature',
    importSimpleKey: 'Import manuel',
    // IMPORT CONTRACT DIALOG
    // CLIENT CRUD DIALOG
    clientTitle: 'Manage Client',
    contactTitle: 'Manage Contact',
    signatoriesTitle: 'Manage Signatory',
    clientForeign: 'Foreign',
    clientName: 'Name',
    clientLegalStatus: 'Legal status',
    clientCapital: 'Capital',
    clientAddress: 'Address',
    clientPostalCode: 'Code postal',
    clientCity: 'Ville',
    clientCedex: 'Cedex',
    clientRcs: 'Rcs',
    clientCityRcs: 'City Rcs',
    clientSiret: 'Siret',
    clientRepresenting: 'Representing',
    clientQuality: 'Quality',
    contactFullName: 'Name',
    contactPhone: 'Phone',
    contactEmail: 'Email',
    signatoryFullName: 'Name',
    signatoryEmail: 'Email',
    signatoryQuality: 'Quality',
    // Mission Creation Dialog
    missionTitle: 'Mission creation',
    createMissionButton: 'Create mission',
    // Other
    civilStatus: 'Civil status',
    male: 'Masculine',
    female: 'Female',
    firstname: 'Firstname',
    lastname: 'Lastname',
    contract: "Contract",
    clientContract: 'Contract customer',
    workContract: "Work contract",
    clientFinal: 'Final customer',
    mission: 'Mission',
    profil: 'Profil',
    documents: 'Documents',
    alone: "Alone",
    married: "Married",
    concubine: "Concubine",
    belowFive: "-5 years",
    upperFive: "+5 years",
    individual: "Individual",
    familial: "Familial",
    none: "None",
    website: "Website",
    network: "Network",
    beginDate: 'Begin date',
    endDate: 'End date',
    status: 'Statut',
    contractType: 'Contract type',
    salesManager: 'Sales manager',
    renewal: 'Renewal',
    phone: 'Phone',
    email: 'Email',
    missionsContracts: 'Missions contracts',
    password: 'Password',
    forgot: 'Forgot password?',
    login: 'Login',
    address: 'Address',
    postalCode: 'Postal code',
    city: 'City',
    birthPlace: 'Birth place',
    birthDate: 'Birth date',
    nationality: 'Nationality',
    frenchM: 'French',
    frenchF: 'French',
    foreigner: 'Foreigner',
    validateDeclarePaie: 'Valider la déclaration de paie',
    socialSecurityNumber: 'Social security number',
    isPassportQuestion: "Do you have a passport ?",
    passportNumber: "Passeport number",
    expirationDate: "Expiration date",
    residencePermit: "Residence permit",
    personalSituation: "Personal situation",
    healthIssuesQuestion: "Health issues ?",
    handicapQuestion: "Handicap ?",
    medicalVisit: "Medical visit",
    endContract: 'End of contract',
    createdDate: 'Creation date',
    dailyRate: 'Daily rate',
    search: 'Search',
    export: 'Export',
    exportPdf: 'Export PDF',
    refundAll: 'Refund all',
    clear: 'Clear',
    validate: 'Validate',
    all: 'All',
    yes: 'Yes',
    no: 'No',
    simulation: 'Simulation',
    prospect: 'Prospect',
    view: 'View {target}',
    save: 'Save',
    send: 'Send',
    job: 'Job',
    total: 'Total',
    NDF: 'Expense report',
    year: 'Year',
    month: 'Month',
    paidHT: 'Paid (excl. VAT)',
    paidNet: 'Paid (incl. VAT)',
    managementFee: 'Management fee',
    managementFeeRate: 'Management fee rate',
    otherMissions: 'On-call and other missions',
    acompte: 'Down payment',
    cotisationsPatronales: 'Employer contribution',
    totalCotisations: 'Total contributions',
    brut: 'Gross',
    fraisAnnexes: 'Additional costs',
    abonnementSport: 'Sports membership',
    loisirs: 'Leisure/culture',
    chequesCadeau: 'Gift vouchers',
    chequesCulture: 'Culture vouchers',
    cesu: 'CESU',
    pee: 'PEE',
    perco: 'PERCO',
    envoi: 'Envoi pour les chèques et ouverture PEE',
    fraisRefactures: 'Mission expenses rebilled',
    tjm: 'Average daily rate',
    nbJoursCra: 'Activity report days',
    nomFichier: 'Filename',
    taille: 'Size',
    date: 'Date',
    beneficiare: 'Recipient',
    libelle: 'Name',
    category: 'Category',
    nbDepenses: 'Number of expenses',
    tvaRecuperee: 'VAT retrieved',
    nature: 'Nature',
    iban: 'IBAN',
    ibanSalary: "IBAN Salary",
    skillsDetails: "Skills details",
    montant: 'Amount',
    type: 'Type',
    etat: 'State',
    entreprises: 'Companies',
    references: 'Identification',
    missions: 'Missions',
    syntheseGenerale: 'General overview',
    syntheseNDF: 'Expense report / Social devices / Supplier purchases overview',
    syntheseTraitement: 'In process',
    syntheseCA: 'Revenues overview',
    attenteReglement: 'Pending settlement',
    cumulReserve: 'Reserve pool',
    activitesAttente: 'Pending activities',
    avance: 'Advanced',
    facturation: 'Billing',
    gestion: 'Management',
    dispositifsSociaux: 'Social devices',
    both: "Both",
    salary: "Salary",
    salaires: 'Salaries',
    baseSalary: "Base salary",
    contractualisation: "Contract",
    workContractDocumentName: 'Work Contract',
    contractDocumentName: 'Contract',
    contractAmendmentDocumentName: 'Contract Amendment',
    saveDraftMission: 'Save',
    createDraftMission: 'Create new mission',
    // ERRORS
    errors: {
        accountNotFound: 'Account not found',
        invalidEmail: 'L\'email renseigné est invalide',
        fieldRequired: 'All fields {symbole} required',
        invalidToken: 'Invalid token',
        deleteLastDueDate: 'Vous ne pouvez pas supprimer la dernière échéance',
        importPays: 'An error has occurred at the pays import',
        sendSimulation: 'An error has occurred when sending simulation',
        adminCommercialFormUnselectGeneralConditionTemplate: 'Choose one type of general conditions',
        adminCommercialFormUnUploadedFile: 'You have to import your own terms and conditions to be able to register',
        adminCommercialFormUnSelectedContract: 'Choose at least one contract type',
        adminCommercialFormTauxReserveFinanciereUnset: 'Un taux de réserve financière est manquant',
        adminAdvFormBankAccountUnset: 'Un compte bancaire n\'est pas complet',
        adminAdvFormBillingAccountUnset: 'Un compte d\'affacturage n\'est pas complet',
        adminAdvFormDateNotificationUnset: 'Veuillez sélectionner une régularité',
        adminAdvInvoiceNaming: 'Veuillez renseigner une nommenclature de facture et un index de départ',
        adminCommercialFormManagementRoofAmountUnset: 'La valeur du plafond est manquante',
        adminRhFormUnUploadedFile: 'Importez vos conditions générales pour enregistrer',
        adminRhFormCreditCardFeesUnset: 'Les frais de carte sont manquants',
        adminRhFormRestaurantsTicketsAmountUnset: 'Le nombre de tickets restaurants est manquant',
        contractNotImported: 'You have to import the contract to validate',
    },
    // SUCCESS
    success: {
        saveDocument: 'Document save',
        deleteDocument: 'Document deleted',
        saveSimulation: 'Simulation save',
        sendSimulation: 'Simulation send',
        savePay: 'Paie sauvegardée',
        saveDeclarePay: 'Déclaration de paie sauvegardée',
        declarePaieValidate: 'Déclaration de paie validée',
        saveDeclareFacturation: 'Déclaration de facturation sauvegardée',
        declareFacturationValidate: 'Déclaration de facturation validée',
        saveMission: 'Mission save',
        saveProfil: 'Profil save',
        importPays: 'Import success',
        forcePaysDeclaration: 'Pays declared',
    },
    // WARNING
    warning: {},
    // INFO
    info: {},
};

export default translation;
