import axios from "@/plugins/axios";

export default {
  namespaced: true,

  state: {
  },
  mutations: {
  },
  getters: {
  },
  actions: {
    async getRadarChartData(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/alerts/equipment/' + data.id + '/graph/radar', data).then(res => {
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getHeatmapChartData(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/alerts/equipment/' + data.id + '/graph/heatmap', data).then(res => {
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },
    async getLineChartData(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/alerts/equipment/' + data.id + '/graph/line', data).then(res => {
          resolve(res)
        }).catch((e) => {
          reject(e)
        })
      })
    },
  },
}
