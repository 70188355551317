import {store} from "@/store";
import router from "@/router";

const axios = require('axios');
const axiosApiInstance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL
});

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error.config;
    if (error.response.status >= 400 && !originalRequest._retry && originalRequest.url !== '/auth/refresh') {
        originalRequest._retry = true;
        const token = await store.dispatch('auth/refresh')
        originalRequest.headers['Authorization'] = `Bearer ${token}`
        return axiosApiInstance(originalRequest);
    } else if (originalRequest.url === '/auth/refresh') {
        await store.dispatch('auth/logout')
        router.push({ name: 'Login'})
    } else {
        try {
            if (error.response?.data?.data?.errors) store.commit('misc/SET_TOAST_MESSAGE', error.response)
            else store.commit('misc/SET_TOAST_MESSAGE', [{message: 'Une erreur s\'est produite'}])
        } catch (e) {
            console.log(e)
            store.commit('misc/SET_TOAST_MESSAGE', [{message: 'Une erreur s\'est produite'}])
        }
        store.state.misc.loading = false
    }
    return Promise.reject(error);
});

export default {
    axiosApiInstance
};
