export default [
    {
        path: '/',
        redirect: { name: 'Map' }
    },
    {
        path: '/connexion',
        name: 'Login',
        meta: {title: 'ParkPilot - Connexion'},
        component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Login')
    },
    {
        path: '/reinitialisation',
        name: 'Reset',
        meta: {title: 'ParkPilot - Réinitialisation'},
        component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Reset')
    },
    {
        path: '/supervision',
        children: [
            {
                path: 'carte',
                name: 'Map',
                meta: {title: 'ParkPilot - Carte'},
                component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Monitoring/Map')
            },
            {
                path: 'site/:id',
                name: 'SiteEquipments',
                meta: {title: 'ParkPilot - Visualisation des équipements'},
                component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Monitoring/SiteEquipments')
            },
        ]
    },
    {
        path: '/gestion',
        children: [
            {
                path: 'sites',
                name: 'ManagementSites',
                meta: {title: 'ParkPilot - Gestion des sites', roles: ['ADMIN']},
                component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Management/Sites')
            },
            {
                path: 'equipements',
                name: 'ManagementEquipments',
                meta: {title: 'ParkPilot - Gestion des équipements', roles: ['ADMIN']},
                component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Management/Equipments')
            },
            {
                path: 'utilisateurs',
                name: 'ManagementUsers',
                meta: {title: 'ParkPilot - Gestion des utilisateurs', roles: ['ADMIN']},
                component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Management/Users')
            },
            {
                path: 'activite',
                name: 'ManagementActivity',
                meta: {title: 'ParkPilot - Gestion de l\'activité', roles: ['ADMIN']},
                component: () => import(/* webpackChunkName: "group-user" */ '/src/views/Pages/Management/Activity')
            },
        ]
    },
]
