import axios from "@/plugins/axios";

export default {
  namespaced: true,

  state: {
    me: null,
    isLoggedIn: false,
    token: null,
    refresh: null,
  },
  mutations: {
    SET_LOGGED_IN(state, val) {
      state.isLoggedIn = val
    },
    SET_TOKEN(state, val) {
      state.token = val
    },
    SET_REFRESH(state, val) {
      state.refresh = val
    },
    SET_ME(state, val) {
      state.me = val
    },
  },
  actions: {
    async login({ commit }, data) {
      return new Promise((resolve, reject) => {
        const rememberMe = data.remember
        delete data.remember
        axios.axiosApiInstance.post('/auth/login', data).then(async (res) => {
          commit('SET_LOGGED_IN', true)
          commit('SET_TOKEN', res.data.token.accessToken)
          axios.axiosApiInstance.defaults.headers.common['Authorization'] = `Bearer ${res.data.token.accessToken}`
          if (rememberMe) {
            commit('SET_REFRESH', res.data.token.refreshToken)
          }
          commit('SET_ME', res.data.user)
          resolve(res);
        }).catch((e) => {
          commit('SET_LOGGED_IN', false)
          reject(e);
        })
      });
    },
    async refresh({ commit, state }) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/auth/refresh', { refreshToken: state.refresh })
          .then((res) => {
            commit('SET_TOKEN', res.data.token.accessToken)
            commit('SET_REFRESH', res.data.token.refreshToken)
            axios.axiosApiInstance.defaults.headers.common['Authorization'] = `Bearer ${res.data.token.accessToken}`
            resolve(res.data.token.accessToken)
          })
          .catch((e) => {
            reject(e);
          })
      })
    },
    async requestResetPassword(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/users/changePasswordRequest', data)
          .then((res) => {
            resolve(res.data)
          })
          .catch((e) => {
            console.log(e)
            reject(e);
          })
      })
    },
    async resetPassword(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/users/changePassword', data)
          .then((res) => {
            resolve(res.data)
          })
          .catch((e) => {
            console.log(e)
            reject(e);
          })
      })
    },
    async acceptInvite(_, data) {
      return new Promise((resolve, reject) => {
        axios.axiosApiInstance.post('/acceptInvite', data)
          .then((res) => {
            resolve(res.data)
          })
          .catch((e) => {
            console.log(e)
            reject(e);
          })
      })
    },
    async logout({ commit }) {
      commit('SET_LOGGED_IN', false)
      commit('SET_TOKEN', null)
      commit('SET_REFRESH', null)
      commit('SET_ME', null)
    },
  },
}
