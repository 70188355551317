export default {
  namespaced: true,

  state: {
    selected: null,
    sideContent: {
      isVisible: false,
      profil: {
        isVisible: false,
        fullname: '',
        picture: null
      },
      currentPageTitle: '',
      subItems: [],
    },
    activeItem: 0
  },
  mutations: {
    setSelected (state, selected) {
      state.selected = selected
    },
    setSideContent (state, data) {
      state.sideContent = data
    },
    setActiveItemSideContent (state, index) {
      state.activeItem = index
    }
  },
  getters: {
    getSelected (state) {
      return state.selected
    }
  },
  actions: {
    changeMenu ({ commit }, data) {
      commit('setSelected', data)
    },
    updateSideContent ({ commit }, data) {
      commit('setSideContent', data)
    },
    updateActiveItemSideContent ({ commit }, index) {
      commit('setActiveItemSideContent', index)
    }
  }
}
